<template>
  <div class="flex flex-col">
    <label class="block text-xs font-semibold text-typography-primary font-geist py-[6px]">
      {{ label }}
    </label>

    <div class="flex gap-3">
      <div
        class="h-[44px] w-[44px] rounded border-neutral-300 border"
        :style="{
          backgroundColor: model as string,
        }"
        @click="handleClick()"
      >
        <input type="color" v-model="model" class="opacity-0" ref="colorInput" />
      </div>
      <Input placeholder="<- Use the Color Picker" v-model="model" @input="validateColor" />
    </div>
    <p v-if="!isValid" class="text-red-500 mt-1 text-xs">
      Invalid color format
    </p>
  </div>
</template>

<script lang="ts" setup>
import Input from './Input.vue';
import { ref } from 'vue';
const model = defineModel<any>();
const colorInput = ref<HTMLInputElement>();
const isValid = ref<boolean>(true);

defineProps({
  label: {
    default: '',
    type: String,
  },
});

function handleClick() {
  colorInput.value?.click();
}


//TODO: To be Switched out with pulse Validation Group
function validateColor() {
  if (model.value && model.value[0] !== '#') {
    model.value = '#' + model.value;
  }

  isValid.value = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/.test(model.value);
}
</script>
